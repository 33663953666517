
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    // 获取数据
    const store = useStore()
    store.dispatch('article/getListAction')
    const list = computed(() => {
      return store.state.article.list
    })

    const router = useRouter()
    const tohome = () => {
      router.push({
        name: 'Home',
        query: {
          isback: 1
        }
      })
    }
    const tohomeWork = () => {
      router.push({
        name: 'Home',
        query: {
          isback: 1,
          top: 1
        }
      })
    }
    const toDetail = (index: number) => {
      router.push({
        name: 'workdetail',
        params: {
          id: index
        }
      })
    }
    window.onscroll = function () {
      const dom = document.querySelector('.work')
      console.log(document.documentElement.scrollTop)
    }
    return { tohome, toDetail, list, tohomeWork }
  }
})
